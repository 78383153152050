<template>
  <div class="planner-page">
    <v-row justify="center">
      <v-col md="6">
        <v-date-picker 
          class="planner-date-picker"
          v-model="createEditActivitySheet.obj.date" 
          full-width
          :events="monthEvents.map(event => event.date)"
          @update:picker-date="getMonthEvents"
          :event-color="getDateEventColor"
          no-title
          elevation="1"
        > 
            <v-spacer></v-spacer>
        </v-date-picker>

        <v-card class="mt-2 pb-2" max-width="auto">
            <v-tabs
              v-model="selectedActivityTab"
              align-with-title
              center-active
              @change="setSelectedActivityType"
            >
            <v-tabs-slider :color="activityTypes[selectedActivityTab].color"></v-tabs-slider>
            <v-tab
                v-for="item in activityTypes"
                :key="item.id"
                :style="`color: ${item.color}`"
            >
                {{ item.name }}
            </v-tab>
            </v-tabs>

        <intract-smart-list
          ref="activity-smart-list"
          class=""
          disable-search
          :endpoint="plannerActivityEndpoint"
          :filter-fields="currentUser.is_admin ? filterFields : null"
        >
          <template v-slot:list-item="{ item: activity }">
            <div class="staff-list-container">
              <v-card class="my-2 mx-2 post-card" elevation="0">
                <v-list-item>
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-medium text-wrap"> 
                        {{ activity.title }} 
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-wrap"> 
                       <p class="mb-0">
                        {{ activity.description }}
                      </p> 
                      <div v-if="activityTypes[selectedActivityTab].allow_rooms && activity.rooms && activity.rooms.length > 0">
                        <v-chip outlined v-for="room_id in activity.rooms" :key="room_id" class="mt-1 mr-1" small color="primary" >
                          {{ allRooms.find(room => room.id == room_id) ? allRooms.find(room => room.id == room_id).sections : "" }}
                        </v-chip>
                      </div>
                      <div v-if="activityTypes[selectedActivityTab].allow_subjects && typeof(activity.subjects) == 'number'">
                        <v-chip outlined class="mt-1 mr-1" small color="primary" v-if="activity.rooms && activity.subjects">
                          {{ allRooms.find(room => room.id == activity.rooms) ? allRooms.find(room => room.id == activity.rooms).sections : ""  }} - {{ activity.subject_name }}
                        </v-chip>
                      </div>
                      </v-list-item-subtitle>
                  </v-list-item-content>

                  <!-- <v-list-item-icon class="align-self-center mr-n3"> -->
                      <!-- <v-icon> mdi-three-dots </v-icon>
                  </v-list-item-icon>  -->
                  <v-list-item-icon class="align-self-center mr-n3">
                      <intract-drop-down-menu
                        v-if="currentUser.is_admin" 
                        :options-list="getDropdownOptions(activity)"
                      />
                  </v-list-item-icon>
                </v-list-item> 
              </v-card>
            </div>
          </template>
        </intract-smart-list>

        </v-card>

      </v-col>
    </v-row>

      <!-- :title="createEditActivitySheet.editId ? `Edit Item - ${activityTypes[selectedActivityTab].name}` : `Add Item - ${activityTypes[selectedActivityTab].name}`" -->
<!-- 
      :title="createEditActivitySheet.editId ? `Edit Item - ${activityTypes[selectedActivityTab].name}` : `Add ${item.verbose_singular}`"     -->
      
    <intract-create-edit-sheet
      persistent
      ref="activity-create-edit-sheet"
      :title="createEditActivitySheet.editId ? `Edit Item - ${activityTypes[selectedActivityTab].verbose_singular}` : `Add ${activityTypes[selectedActivityTab].verbose_singular}`"
      :description=" moment(createEditActivitySheet.obj.date).format('LL')"
      :visible="createEditActivitySheet.visible"
      v-if="currentUser.is_admin"
      @close="
        createEditActivitySheet.visible = false;
        createEditActivitySheet.editId = null;
      "
      :fields="formFields"
      :endpoint="endpoints.plannerActivityViewSet"
      :edit-id="createEditActivitySheet.editId"
      :data-object="createEditActivitySheet.obj"
      :get-object="beforeSubmit"
      create-success-message="Activity added successfully!"
      edit-success-message="Activity edited successfully!"
      @objectCreated="(obj) =>{
        $refs['activity-smart-list'].getItems();
        getMonthEvents(obj.date)
      }"
      @updateObject="(obj) => (createEditActivitySheet.obj = obj)"
    >

      <!-- subjects custom field -->
      <template v-slot:custom-field--subjects="{ obj }">
        <v-select
          outlined
          label="Subjects"
          :items="roomSubjects"
          item-text="name"
          v-model="obj.subjects"
          item-value="id"
          :disabled="!obj.rooms || isLoading"
        ></v-select>
      </template>
    </intract-create-edit-sheet>

    <confirmation-dialog
      :visible="showDeleteActivityDialog"
      title="Are you sure you want to delete this activity?"
      @successCallback="deleteActivity(toBeDeletedItem)"
      @failureCallback="
        showDeleteActivityDialog = false;
      "
      delete-dialog
    />

    <v-fab-transition>
      <v-btn
        v-if="!isLoading && currentUser.is_admin"
        color="primary"
        class="non-focused"
        @click="createEditActivitySheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

  </div>

</template>

<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
import IntractCreateEditSheet, { CreateEditFieldTypes,} from "@components/generics/IntractCreateEditSheet.vue";
import moment from "moment";
import { mapGetters } from 'vuex';

export default {
  name: "Planner",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  components: {
    IntractSmartList,
    IntractDropDownMenu,
    IntractCreateEditSheet,

  },
  data() {
    return { 
        moment: moment,
        selectedActivityTab: 0,
        activityTypes: [],
        roomSubjects: [],
        showDeleteActivityDialog: false,
        createEditActivitySheet: {
          visible: false,
          editId: null,
          obj: {
            activity_type: null,
            title: null,
            description: null,
            date: null,
            show_rooms: false,
            show_subjects: false,
            rooms: null,
            subjects: null,
          },
        },
        monthEvents: [],
    };
  },
  computed: {
    ...mapGetters(["allRooms","isLoading"]),
    plannerActivityEndpoint(){
        let selectedActivityId = this.activityTypes && this.activityTypes.length > 0 ? this.activityTypes[this.selectedActivityTab].id : null;
        if(!selectedActivityId)
          return null
        var url = this.Helper.addUrlParams(this.endpoints.plannerActivityViewSet, [`activity_type=${selectedActivityId}`, `date=${this.createEditActivitySheet.obj.date}`]);
        if (!this.currentUser.is_admin && this.currentUser.is_faculty)
          url = this.Helper.addUrlParams(url, [
            "subjects__faculty__id=" + this.currentUser.id,
          ])
        return url;
    },

    // roomSubjectsEndpoint(){
    //   if(!this.createEditActivitySheet.obj.rooms)
    //     return null
    //   var url = this.Helper.addUrlParams(this.endpoints.subjectViewSet,[`room=${this.createEditActivitySheet.obj.rooms}`])
    //   // this.roomSubjects = await api.call(this.es)
    //   return url
    // },

    filterFields(){
      let selectedActivity = this.activityTypes[this.selectedActivityTab] 
      return{
        ...selectedActivity.allow_rooms || selectedActivity.allow_subjects ? {
          rooms: {
            model: null,
            items: this.allRooms,
            multiple: selectedActivity.allow_subjects ? false : true , 
            label: 'Filter by Classroom',
            itemValue: 'id',
            itemText: 'sections',
            param: 'rooms__id__in',
          }
        } : null,
      }
    },

    formFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: false,
          md: 12, 
        },
        // activity_type: {
        //   fieldType: CreateEditFieldTypes.SELECT,
        //   label: "Activity Type",
        //   required: true,
        //   endpoint: this.endpoints.activityTypeViewSet,
        //   md: 12,
        //   itemValue: "id",
        //   itemText: "name",
        // },
        show_rooms: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Only show to selected classrooms",
          required: false,
          hide: !this.activityTypes[this.selectedActivityTab].allow_rooms,
          md: 12,
          // value: this.createEditActivitySheet.obj.show_rooms 
        },
        show_subjects: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Only show to selected subjects",
          required: false,
          hide: this.activityTypes[this.selectedActivityTab].allow_rooms || !this.activityTypes[this.selectedActivityTab].allow_subjects,
          md: 12,
          // value: this.createEditActivitySheet.obj.show_subjects
        },
        rooms: {
          fieldType: CreateEditFieldTypes.SELECT,
          label:  this.createEditActivitySheet.obj.show_rooms ? "Classrooms" : "Classroom",
          required: true,
          items: this.allRooms,
          multiple: this.createEditActivitySheet.obj.show_rooms ? true : false,
          hide: !(this.createEditActivitySheet.obj.show_rooms || this.createEditActivitySheet.obj.show_subjects),
          md: 12,
          itemValue: 'id',
          itemText: 'sections'
        },
        subjects: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          hide: !this.createEditActivitySheet.obj.show_subjects,
          md: 12,
        },
      };
    },

    appBarOptions() {
      return this.currentUser.is_support_account ? {
        iconButton: { 
            icon: "mdi-cog", 
            action: () => {
                this.$router.push({
                    name: "ActivityType",
                });
            }, },
      } : null;
    },
  }, 
    
  methods: {

    getDateEventColor(date){
      let colors = this.monthEvents.filter(event => event.date == date ).map(event => event.color)
      colors = Array.from(new Set(colors))
      return colors
    },

    beforeSubmit(obj){
      if(!Array.isArray(obj.rooms))
        {
          if(obj.rooms)
            obj.rooms = [obj.rooms ,]
          else
            obj.rooms = []
        }
      if(!Array.isArray(obj.subjects)){
        if(obj.subjects)
          obj.subjects = [obj.subjects,]
        else
          obj.subjects = []
      }
      return obj
    },

    getDropdownOptions(activityItem) {
      var self = this;
      return [
        {
          value: 1, 
          text: "Edit", 
          icon: "mdi-pencil",
          type: "edit",
          action() {
            // self.createEditActivitySheet.obj.creator = activityItem.user.id;
            // self.createEditActivitySheet.obj.institution = self.currentInstitution.id;
            self.createEditActivitySheet.editId = activityItem.id;
            self.createEditActivitySheet.visible = true;
          }                  
        }, 
        {
          value: 2,
          text: "Delete",
          icon: "mdi-delete",
          type: "delete",
          action() {
            self.toBeDeletedItem = activityItem;
            self.showDeleteActivityDialog = true;
          }          
        }
      ];
    },

    setSelectedActivityType(){
      let selectedActivityId = this.activityTypes && this.activityTypes.length > 0 ? this.activityTypes[this.selectedActivityTab].id : null;
      this.createEditActivitySheet.obj.activity_type = selectedActivityId  
    },

    async getAllActivityTypes(){
      this.activityTypes = await this.api.call(this.essentials, this.endpoints.activityTypeViewSet)
    },

    async getMonthEvents(yearMonth){
      let year = yearMonth.split("-")[0]
      let month = yearMonth.split("-")[1]
      // this.createEditActivitySheet.obj.date = yearMonth + "-01"
      var url = this.Helper.addUrlParams(this.endpoints.plannerActivityViewSet + "month_events/",[
        `date__month=${month}`,
        `date__year=${year}`
      ]);
      this.monthEvents = await this.api.call(this.essentials, url)
    },

    async deleteActivity() {
      this.showDeleteActivityDialog = false;
      var activityId = this.toBeDeletedItem.id;
      var activityDate = this.Helper.clone(this.toBeDeletedItem.date);
      var url = this.endpoints.plannerActivityViewSet + activityId + "/";
      this.toBeDeletedItem = null;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Activity has been successfully deleted!",
          type: "success",
        });
        await this.$refs['activity-smart-list'].getItems();
        this.getMonthEvents(activityDate)  
      }
    },
   
  },
  watch:{
    'createEditActivitySheet.obj.date': function(new_date,old_date){
      if(new_date && old_date){
        let new_month = moment(new_date).month()
        let old_month = moment(old_date).month()
        if(new_month!=old_month){
          console.log("month changed")
        }
      }
    },
    'createEditActivitySheet.obj.rooms': async function(){
      if(this.createEditActivitySheet.obj.show_subjects){
        // console.log(this.createEditActivitySheet.obj.rooms) 
        if(this.createEditActivitySheet.obj.rooms){
          var url = this.Helper.addUrlParams(this.endpoints.subjectViewSet, [`room=${ this.createEditActivitySheet.obj.rooms }`])
          this.roomSubjects = await this.api.call(this.essentials, url)
       }
      }
    }
  },

  async created(){
    if(this.$route.query.date){
      this.createEditActivitySheet.obj.date = this.$route.query.date
    }
    else{
      this.createEditActivitySheet.obj.date = moment().format('YYYY-MM-DD')
    }
    await this.getAllActivityTypes();
    this.createEditActivitySheet.obj.activity_type = this.activityTypes[0].id;
  }
    

}
</script>

<style>
.v-date-picker-table__events > div{
  border-radius: 0% !important;
  height: 3px !important;
  /* width: 15px !important; */
}
</style>